import { useQuery } from "react-query";
import { fetchBacktestReport, fetchBacktestTimeseries, fetchDrawdown, fetchMeanQuantiles, fetchPerformanceMetrics, fetchPerformanceTimeline, fetchRebalanceReturns, fetchRollingStats } from "../api/api";
import { BacktestChartItem, BacktestReportItem, BacktestReportResponse, BacktestType, DrawdownType, FactorMeanQuantileResponseType, PerformanceMetricsResponseType, PerformanceTimelineResponseType, RebalanceReturnsType, RollingStatsListType } from "../api/schema";
import { useStrategiesQuery } from "./useStrategies";

export const useBacktestTimeseriesQuery = <TData = BacktestChartItem[]>(userId: string = "", strategyId: string = "", backtestId: string = "", select?: (strategies: BacktestChartItem[]) => TData) => useQuery<BacktestChartItem[], Error, TData>({
    queryKey: ['backtest-timeseries', userId, strategyId, backtestId],
    queryFn: () => fetchBacktestTimeseries(userId, strategyId, backtestId),
    select,
    enabled: !!userId && !!strategyId && !!backtestId
});


export const useBacktestQuery = (strategyId: string, backtestId: string) => useStrategiesQuery<BacktestType>((strategies) => strategies.find((strategy) => strategy.PK === strategyId)?.backtests.find((backtest) => backtest["backtest-id"] === backtestId) as BacktestType);

export const useBacktestReportQuery = <TData = BacktestReportResponse>(userId?: string, strategyId?: string, backtestId?: string, select?: (strategies: BacktestReportResponse) => TData) => useQuery<BacktestReportResponse, Error, TData>({
    queryKey: ['backtest-report'],
    queryFn: () => fetchBacktestReport(userId, strategyId, backtestId),
    select,
    enabled: !!userId && !!strategyId && !!backtestId
});

export const useRebalanceReturnsQuery = <TData = RebalanceReturnsType>(userId: string, strategyId?: string, backtestId?: string, select?: (strategies: RebalanceReturnsType) => TData) => useQuery<RebalanceReturnsType, Error, TData>({
    queryKey: ['rebalance-returns', strategyId, backtestId],
    queryFn: () => fetchRebalanceReturns(userId, strategyId || "", backtestId || ""),
    select,
    enabled: !!userId && !!strategyId && !!backtestId
});

export const useRollingStatsQuery = <TData = RollingStatsListType>(userId?: string, strategyId?: string, backtestId?: string, select?: (strategies: RollingStatsListType) => TData) => useQuery<RollingStatsListType, Error, TData>({
    queryKey: ['rolling-stats', strategyId, backtestId],
    queryFn: () => fetchRollingStats(userId || "", strategyId || "", backtestId || ""),
    select,
    enabled: !!userId && !!strategyId && !!backtestId
});

export const useMeanQuantileQuery = <TData = FactorMeanQuantileResponseType>(userId?: string, strategyId?: string, select?: (strategies: FactorMeanQuantileResponseType) => TData) => useQuery<FactorMeanQuantileResponseType, Error, TData>({
    queryKey: ['mean-quantile', strategyId],
    queryFn: () => fetchMeanQuantiles(userId || "", strategyId || ""),
    select,
    enabled: !!userId && !!strategyId
});

export const useDrawdownQuery = <TData = DrawdownType>(userId?: string, strategyId?: string, backtestId?: string, select?: (strategies: DrawdownType) => TData) => useQuery<DrawdownType, Error, TData>({
    queryKey: ['drawdown', strategyId, backtestId],
    queryFn: () => fetchDrawdown(userId || "", strategyId || "", backtestId || ""),
    select,
    enabled: !!userId && !!strategyId && !!backtestId
});

export const usePerformanceMetricsQuery = <TData = PerformanceMetricsResponseType>(userId?: string, strategyId?: string, backtestId?: string, select?: (strategies: PerformanceMetricsResponseType) => TData) => useQuery<PerformanceMetricsResponseType, Error, TData>({
    queryKey: ['performance-metrics', strategyId, backtestId],
    queryFn: () => fetchPerformanceMetrics(userId || "", strategyId || "", backtestId || ""),
    select,
    enabled: !!userId && !!strategyId && !!backtestId
});


export const usePerformanceTimelineQuery = <TData = PerformanceTimelineResponseType>(userId?: string, strategyId?: string, backtestId?: string, select?: (strategies: PerformanceTimelineResponseType) => TData) => useQuery<PerformanceTimelineResponseType, Error, TData>({
    queryKey: ['performance-timeline', strategyId, backtestId],
    queryFn: () => fetchPerformanceTimeline(userId || "", strategyId || "", backtestId || ""),
    select,
    enabled: !!userId && !!strategyId && !!backtestId
});