import { Pagination } from "react-bootstrap"

const Paginator = ({
    pageSize = 5,
    items = 0,
    activePage,
    onPageChange
}: {
    pageSize?: number;
    items: number;
    activePage: number;
    onPageChange: (page: number) => void;
}) => {
    const pages = Math.ceil(items / pageSize);
    let middlePages:number[] = [];
    if (activePage === 0) {
      middlePages = [activePage + 1, activePage + 2]
    } else if (activePage === 1) {
        middlePages = [activePage, activePage + 1, activePage + 2]
    } else if (activePage === pages - 2) {
      middlePages = [activePage - 1, activePage]
    } else if (activePage === pages - 1) {
        middlePages = [activePage - 2, activePage - 1]
    } else if (activePage > 1 && activePage < pages - 1) {
        middlePages = [activePage - 1, activePage, activePage + 1]
    } 


  return (
    <div style={{
      display: "flex",
      justifyContent: "center",
    }}>
      <Pagination>
        <Pagination.First 
          onClick={() => onPageChange(0)} 
          disabled={activePage === 0} />
        <Pagination.Prev
          onClick={() => onPageChange(activePage - 1)}
          disabled={activePage === 0} />
        <Pagination.Item 
          active={activePage === 0}
          onClick={() => onPageChange(0)}>
            1
        </Pagination.Item>
        {pages > 4 && activePage > 2 && <Pagination.Ellipsis />}
        {middlePages.map((page, index) => (
          <Pagination.Item
            key={index}
            active={activePage === page}
            onClick={() => onPageChange(page)}>
            {page + 1}
          </Pagination.Item>
        ))}
        {pages > 4 && (activePage < pages - 2) && <Pagination.Ellipsis />}
        <Pagination.Item
          active={activePage === (pages - 1)}
          onClick={() => onPageChange(pages - 1)}>
          {pages}
        </Pagination.Item>
        <Pagination.Next 
          onClick={() => onPageChange(activePage + 1)}
          disabled={activePage === (pages - 1)} />
        <Pagination.Last 
          onClick={() => onPageChange(pages - 1)}
          disabled={activePage === pages - 1}/>
      </Pagination>
    </div>
  )
}

export default Paginator