import { useParams } from "react-router-dom";
import { useStrategyQuery } from "../../../hooks/useStrategies";
import { useFactorMomentumQuery, useFactoryRankingQuery } from "../../../hooks/useFactors";
import ReactECharts from 'echarts-for-react'; 
import { useEffect, useState } from "react";
import FactorSelector from "../../common/FactorSelector";

export const MomentumChart = () => {

    let { portfolioId } = useParams();
    let [selectedFactor, setSelectedFactor] = useState<string>("");
    const portfolioQuery = useStrategyQuery(portfolioId || "");
    const query = useFactoryRankingQuery("admin", portfolioId);
    const momentumQuery = useFactorMomentumQuery("admin", portfolioQuery.data?.["model-id"], selectedFactor || "");

    useEffect(() => {
        if (query.data ) {
            setSelectedFactor(query.data[3].Factor);
        }
    
    }, [query.data]);
    
    if (momentumQuery.data) {
        if (momentumQuery.data.length === 0) {
            return (
                <>
                    <FactorSelector factor={selectedFactor} onChange={setSelectedFactor} />
                    <div>No data for this factor</div>
                </>
            );
        }

        const options = {
            xAxis: {
                type: 'category',
                data: momentumQuery.data?.map((point: any) => point.Date),
            },
            yAxis: {
              type: 'value',
              name: 'Momentum'
            },
            series: ["Log_Return_Q1", "Log_Return_Q2", "Log_Return_Q3", "Log_Return_Q4", "Log_Return_Q5"].map((type) => {
              return {
                data: momentumQuery.data?.map((point: any) => point[type]),
                type: 'line',
                name: type,
              }
            }),
            tooltip: {
              trigger: 'axis',
            },
          };

          return (
            <>
                <FactorSelector factor={selectedFactor} onChange={setSelectedFactor} />
                <ReactECharts option={options} />
            </>
          );
    } 

    if(momentumQuery.error) {
        console.log(momentumQuery.error);
    }

    return (
        <>
            {/* <Form.Select value={selectedFactor} onChange={(e) => setSelectedFactor(e.target.value)}>
                {query.data?.map((factor) => <option value={factor.Factor}>{factor.Factor}</option>)}
            </Form.Select> */}
            <FactorSelector factor={selectedFactor} onChange={setSelectedFactor} />
            <div>Loading...</div>
        </>
    );
}
