import { zodResolver } from "@hookform/resolvers/zod";
import React, {  createContext, useContext } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { Controller, useForm } from 'react-hook-form';
import { FactorRankingItem } from "../../../api/schema";
const FormContext = createContext({});
const GroupContext = createContext("");


export const CustomForm = ({
    schema,
    onSubmit,
    children
}: {
    schema: any;
    onSubmit: any;
    children: any;
}) => {
    const {
        handleSubmit,
        control,
        formState: { errors },
      } = useForm({
        resolver: zodResolver(schema),
    });

    const onError = (_errors: any, e: any) => {
        e.preventDefault();
        debugger;
    };

  return (
    <FormContext.Provider value={[errors, control]}>
        <Form onSubmit={handleSubmit(onSubmit, onError)}>
            {children}
        </Form>
    </FormContext.Provider>
  );
};

export const FormGroup = ({
    name,
    children
}: {
    name: string;
    children: React.ReactNode;
}) => {
    return (
        <GroupContext.Provider value={name}>
            <Form.Group className="mb-3" controlId={name}>
                {children}
            </Form.Group>
        </GroupContext.Provider>
    );
};

export const FormElement = ({
    defaultValue,
    type,
    disabled,
    ...rest
}: any) => {
    const [errors, control] = useContext(FormContext) as any;
    const name = useContext(GroupContext);

    return (
        <Controller control={control} name={name}                                            
            defaultValue={defaultValue}                                                                    
            render={({ field: { onChange, onBlur, value, ref } }) => {
                const updateValue = (e: any) => {
                    let value = e.target.value;
                    if (type === "number") {
                        value = parseFloat(value);
                    }
                    onChange(value);
                };
                return (                      
                    <Form.Control     
                        onChange={(e) => updateValue(e)}
                        type={type}
                        value={value}
                        ref={ref}                       
                        isInvalid={!!errors[name]}
                        disabled={disabled}
                    />
                )
            }} />     
    );
};

export const FormInputGroup = ({
    children
}: {
    children: React.ReactNode
}) => {
    return (
        <InputGroup>{children}</InputGroup>
    );
};

export const FormElementText = ({
    children
}: {
    children: React.ReactNode;
}) => {
    return (
        <InputGroup.Text>{children}</InputGroup.Text>
    );
};

export const FormCheck = ({
    defaultValue,
    label,
    disabled = false
}: {
    defaultValue: boolean;
    label: string;
    disabled?: boolean
}) => {    
    const [errors, control] = useContext(FormContext) as any;
    const name = useContext(GroupContext);

    return (
        <Controller control={control} name={name}                                            
            defaultValue={defaultValue}                                                                    
            render={({ field: { onChange, onBlur, value, ref } }) => {
                return (                      
                    <Form.Check
                        type="checkbox"
                        onChange={onChange}
                        value={value}
                        ref={ref}
                        label={label}
                        isInvalid={!!errors[name]}
                        disabled={disabled}    
                    />
                )
            }} />     
    );
};

export const FormSelect = ({
    defaultValue,
    options,
    disabled = false
}: {
    defaultValue?: string;
    options: FactorRankingItem[];
    disabled?: boolean;
}) => {    
    const [errors, control] = useContext(FormContext) as any;
    const name = useContext(GroupContext);

    return (
        <Controller control={control} name={name}                                            
            defaultValue={defaultValue}                                                                    
            render={({ field: { onChange, onBlur, value, ref } }) => {
                return (
                    <Form.Select ref={ref} onChange={onChange} value={value} disabled={disabled} >
                        {options.map((selectOption) => (
                            <option value={selectOption.Factor}>{selectOption.Factor}</option>
                        ))}
                    </Form.Select>
                )
            }} />     
    );
};

export const FormError = () => {
    const [errors] = useContext(FormContext) as any;
    const name = useContext(GroupContext);
    return (
        <Form.Control.Feedback type="invalid">                                                     
            {errors[name]?.message}
        </Form.Control.Feedback>   
    );
};

export const FormLabel = ({
    children
}: {
    children: React.ReactNode;
}) => {
    return (
        <Form.Label>{children}</Form.Label>
    );
};

export const FormSubmit = ({
    children
}: {
    children: React.ReactNode;
}) =>{
    const [errors, control] = useContext(FormContext) as any;
    
    return (
        <Controller name="Submit" control={control}                                                                
            render={({ field: { ref }, formState }) => (                                               
            <Button type="submit" disabled={formState.isSubmitting}                                  
                className="btn btn-primary">                                                          
                {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1" />}
                {children}                                                                                 
            </Button>                                                                                
            )} />    
    );

}

CustomForm.Element = FormElement;
CustomForm.InputText = FormElementText;
CustomForm.InputGroup = FormInputGroup;
CustomForm.Group = FormGroup;
CustomForm.Error = FormError;
CustomForm.Label = FormLabel;
CustomForm.CheckBox = FormCheck;
CustomForm.Submit = FormSubmit;
CustomForm.Select = FormSelect;

export default CustomForm;