import { Table } from "react-bootstrap"
import { useStrategyQuery } from "../../../hooks/useStrategies";
import { useRollingStatsQuery } from "../../../hooks/useBacktest";

export const RollingStatsTable = ({
    portfolioId
}: {
    portfolioId: string;
}) => {
    const strategyQuery = useStrategyQuery(portfolioId);
    const rollingStats = useRollingStatsQuery(strategyQuery.data?.["user-id"], strategyQuery.data?.["model-id"], strategyQuery.data?.backtests[0]["backtest-id"]);

    if (rollingStats.data) {
        return (
            <div className="mt-4">
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Strategy</th>
                            <th>Max. Percentage</th>
                            <th>Min. Percentage</th>
                            <th>Fail Rate</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rollingStats.data.map((stat) => (
                        <tr key={stat.strategy}> 
                            <td>{stat.strategy}</td>
                            <td>{stat.max_percent}</td>
                            <td>{stat.min_percent}</td>
                            <td>{stat.min_percent}</td>
                        </tr>))}          
                    </tbody>
                </Table>
            </div>
        );
    }

    if (rollingStats.error) {
        return (
            <div>
                Error fetching rolling stats!
            </div>
        );
    }

    return (
        <div>Loading...</div>
    );
}
