import { useParams } from "react-router-dom";
import { useStrategyQuery } from "../../../hooks/useStrategies";
import { usePerformanceTimelineQuery } from "../../../hooks/useBacktest";
import { PerformanceTimelineResponseType, PerformanceTimelineType } from "../../../api/schema";
import ReactECharts from 'echarts-for-react'; 

export const PerformanceTimelineChart = () => {
    let { portfolioId } = useParams();
    const portfolioQuery = useStrategyQuery(portfolioId || "");
    const performanceTimelineQuery= usePerformanceTimelineQuery("admin", portfolioQuery.data?.["model-id"], portfolioQuery.data?.backtests[0]["backtest-id"]);
    if (performanceTimelineQuery.data) {
        const options = {
            tooltip: {
              trigger: 'axis'
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            toolbox: {
              feature: {
                saveAsImage: {}
              }
            },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: Object.keys(performanceTimelineQuery.data?.Alpha_universe_LongOnly)
            },
            yAxis: {
              type: 'value'
            },
            series: [
                "Beta_universe_LongOnly",
                "Alpha_universe_LongOnly",
                "Beta_universe_LongShort",
                "Alpha_universe_LongShort",
                "Beta_universe_LongTheShort",
                "Alpha_universe_LongTheShort",
                "Beta_universe_Universe",
                "Alpha_universe_Universe",
                "Sharpe_LongOnly",
                "Sharpe_LongShort",
                "Sharpe_LongTheShort",
                "Sharpe_Universe"
            ].map((metric: string) => {
                const data = (performanceTimelineQuery.data?.[metric as unknown as keyof PerformanceTimelineResponseType]);
                return {
                    name: metric,
                    type: 'line',
                    stack: 'Total',
                    data: Object.values(data)
                };
            })
          };
        return (
            <div>
                <ReactECharts option={options} />
            </div>
        );
    }

    if (performanceTimelineQuery.error) {
        return (
            <div>
                Error fetching performance metrics!
            </div>
        );
    }

    return (
        <div>Loading...</div>
    );
}
