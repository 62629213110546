import { Stack } from "react-bootstrap";

const PortfolioIndicator = ({amount, currency}: {
    amount: number;
    currency: string;
}) => {
  return (
    <Stack direction="vertical">
        <div style={{
            textAlign: "left",
            fontSize: "0.75rem"
        }}>Current value: </div>
        <Stack direction="horizontal" style={{
        }}>
            <div style={{
                fontSize: "2rem"
            
            }}>{amount.toLocaleString()}</div>    
            <div className="px-2">{currency}</div>
        </Stack>
    </Stack>
  )
}

export default PortfolioIndicator;
