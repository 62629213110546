import { useParams } from "react-router-dom";
import { useStrategyQuery } from "../../../hooks/useStrategies";
import { useFactorCumulativeReturnQuery, useFactoryRankingQuery } from "../../../hooks/useFactors";
import ReactECharts from 'echarts-for-react'; 
import { useEffect, useState } from "react";
import FactorSelector from "../../common/FactorSelector";

export const CumulativeReturnChart = () => {
    let { portfolioId } = useParams();
    const portfolioQuery = useStrategyQuery(portfolioId || "");
    let [selectedFactor, setSelectedFactor] = useState<string>("");
    const query = useFactoryRankingQuery("admin", portfolioId);
    const cumulativeReturnQuery = useFactorCumulativeReturnQuery("admin", portfolioQuery.data?.["model-id"]);
    useEffect(() => {
      if (query.data ) {
          setSelectedFactor(query.data[3].Factor);
      }
  
  }, [query.data]);

    if (cumulativeReturnQuery.data) {
        const options = {
            xAxis: {
                type: 'category',
                data: cumulativeReturnQuery.data?.map((point: any) => point.Date),
            },
            yAxis: {
              type: 'value',
              name: 'Cumulative Return'
            },
            
            series: [
                "AR1_LogPrice_252",
                "Autocovariance_252",
                "CCI_126",
                "CapExToMarketCap_Position_125",
                "DX_252",
                "DX_504",
                "PLUS_DM_2",
                "PriceCashFlowRatio_Change_252",
                "Price_Position_3",
                "SUV_Coef_8_weeks",
                "SUV_Coef_Diff_8_weeks",
                "Skewness_252",
                "Smooth_22_Volume_ROC_252",
                "Smooth_5_Volume_MOM_22",
                "WMA_2"
                ].map((type) => {
              return {
                data: cumulativeReturnQuery.data?.map((point: any) => point[type]),
                type: 'line',
                name: type,
              }
            }),
            tooltip: {
              trigger: 'axis'
            },
            
          };

          return (
            <>
              <FactorSelector factor={selectedFactor} onChange={setSelectedFactor} />
              <ReactECharts option={options} />
            </>
          );
    } 

    if(cumulativeReturnQuery.error) {
        console.log(cumulativeReturnQuery.error);
    }

    return (
        <>
          <FactorSelector factor={selectedFactor} onChange={setSelectedFactor} />
          <div> Loading....</div>
        </>
    );
}
