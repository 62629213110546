import { createBrowserRouter } from "react-router-dom"
import Root from "../controls/layout/Root";
import Menu from "../controls/layout/menu";
import FullScreenContent from "../controls/layout/FullScreenContent";
import PortfolioList from "../controls/pages/portfolioOverview/PortfolioList";
import FactorsOverview from "../controls/pages/factors/FactorsOverview";
import ReportDetails from "../controls/pages/report/ReportDetails";
import { PortfolioDetail } from "../controls/pages/portfolioDetail/PortfolioDetail";
import { PortfolioSettings } from "../controls/pages/portfolioSettings/PortfolioSettings";

const router = createBrowserRouter([
    {
        path: "/",
        element: (<Root>
            <Menu />
            <FullScreenContent/>
        </Root>),
        children: [
            {
                path: "",
                element: <PortfolioList />
            },
            {
                path: ":portfolioId",
                element: <PortfolioDetail />
            },
            {
                path: ":portfolioId/report",
                element: <ReportDetails />
            },
            {
                path: ":portfolioId/factors",
                element: <FactorsOverview />
            },
            {
                path: ":portfolioId/settings",
                element: <PortfolioSettings />
            }

        ]
    }
    
]);

export default router;