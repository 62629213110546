import '../../App.css';
import '../../assets/styles/index.scss';
import { Amplify } from 'aws-amplify';
import amplifyconfig from '../../amplifyconfiguration.json';


import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';

Amplify.configure(amplifyconfig);
const queryClient = new QueryClient();

function Root({ children }: { children: React.ReactNode }) {
  return (
    <QueryClientProvider client={queryClient}>
          <div className="App">        
            {children}
          </div>
    </QueryClientProvider>
  );
}

export default Root;
