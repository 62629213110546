import { ReportObject } from "../../../api/schema";
import ReactECharts from 'echarts-for-react'; 

const ReportPieChart = ({
  orders
}: {
  orders: ReportObject[];
}) => {
  const options = {
    title: {
      text: 'Companies distribution',
      subtext: '% of portfolio',
      left: 'left'
    },
    tooltip: {
      trigger: 'item'
    },
    series: [
      {
        name: 'Value',
        type: 'pie',
        radius: '50%',
        data: orders.map((order) => ({value: order.Value, name: order.Ticker})),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  };
  return (
    <div>
      <ReactECharts option={options} />

    </div>
  )
}

export default ReportPieChart;