import Form from "react-bootstrap/Form";
import { useParams } from "react-router-dom";
import { useFactoryRankingQuery } from "../../../hooks/useFactors";

const FactorSelector = ({
    factor,
    onChange
}: {
    factor: string;
    onChange: (factor: string) => void;
}) => {
    let { portfolioId } = useParams();
    const query = useFactoryRankingQuery("admin", portfolioId);

    if (query.data) {
        return (
            <Form.Select value={factor} onChange={(e) => onChange(e.target.value)}>
                {query.data?.map((factor) => <option value={factor.Factor}>{factor.Factor}</option>)}
            </Form.Select>
        );
    }

    if (query.error) {
        return (<div></div>);
    }

  return (
    <Form.Select disabled />
  )
}

export default FactorSelector;
