import { Button, Col, FloatingLabel, Form, Row, Stack, Table } from "react-bootstrap";
import Paginator from "../../common/Pagination";
import { useState } from "react";
import { toFixed } from "../../../utils/utils";
import { OrderObject } from "../../../api/schema";

const RebalanceTable = ({
    portfolioId,
    orders
}: {
    portfolioId: string;
    orders: OrderObject[];
}) => {
    const [page, setPage] = useState(0);
    const sortedOrders = orders.sort((a, b) => b.score - a.score);
    const filteredOrders = sortedOrders.slice((page) * 5, (page +1) * 5);
    const showText = false;
    const handleRecalculate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        console.log("Recalculate");
    };

    return (
        <>
            <Stack direction="horizontal">
                <div>
                    <h3>
                    Rebalance Suggestions
                    </h3>
                </div>
                <div className="ms-auto">
                <Form style={{
                    minWidth: "600px"
                }}>
                    <Row style={{
                        alignItems: "end"
                    }}>
                        <Col xs={4}>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Leverage"
                                        className={showText ? "" : "mb-4"}>
                                        <Form.Control
                                                type="number"
                                                min={1}
                                                max={5}
                                                step={0.1} />
                                    </FloatingLabel>
                                    {showText && <Form.Text id="passwordHelpBlock" muted>
                                        Your password must be
                                    </Form.Text>}

                        </Col>
                        <Col xs={4}>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Leverage"
                                className={showText ? "" : "mb-4"}>
                                <Form.Control
                                    type="number"
                                    min={0}
                                    max={1}
                                    step={0.1} />
                            </FloatingLabel>
                            {showText && <Form.Text id="passwordHelpBlock" muted>
                                Your password must be
                            </Form.Text>}   
                        </Col>
                        <Col xs={4}>
                            <Button onClick={handleRecalculate} variant="light" type="submit" className="mb-4">Recalculate</Button>
                        </Col>
                        </Row>
                    </Form>
                </div>
            </Stack>
        <Table striped bordered hover responsive>
            <thead>
                <tr>
                    <th>Ticker</th>
                    <th>Score</th>
                    <th>Current Value</th>
                    <th>Current Quantity</th>
                    <th>Asset Price</th>
                    <th>Final Quantity</th>
                    <th>Final Value</th>
                    <th>Order Quantity</th>
                    <th>Order Value</th>
                </tr>
            </thead>
            <tbody>
                {filteredOrders.map((order) => {
                    return (
                        <tr>
                            <th>{order.symbol}</th>
                            <th>{toFixed(order.score)}</th>
                            <th>{toFixed(order.current_value)}</th>
                            <th>{toFixed(order.current_qty)}</th>
                            <th>{toFixed(order.current_asset_price)}</th>
                            <th>{toFixed(order.final_qty)}</th>
                            <th>{toFixed(order.final_value)}</th>
                            <th>{toFixed(order.order_qty)}</th>
                            <th>{toFixed(order.order_value)}</th>
                        </tr>        
                    );
                })}
            </tbody>
        </Table>
        <Stack direction="horizontal">
            <div>
                <Paginator items={orders.length} activePage={page} onPageChange={setPage}/>
            </div>
            <div className="ms-auto">
                <Button variant="primary" type="submit">Send to Alpaca</Button>
            </div>
        </Stack>
        </>
    )
}

export default RebalanceTable;
