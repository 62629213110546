import  Stack from "react-bootstrap/Stack";
import { Apps } from "./Apps";
import { User } from "./User";
import { ReactComponent as CCFRIcon }  from "../../../assets/images/ANP_logo_col.svg";
import { Link } from "react-router-dom";

const Menu = () => {
  return (
    <Stack direction="horizontal" gap={3}>
        <div className="p-2">
            <Link to="/">
              <CCFRIcon style={{
                      height: "3rem"
              }}/>
            </Link>
        </div>
        <div className="ms-auto p-2">
            <Apps />            
        </div>
        <div className="p-2">
            <User />
        </div>
    </Stack>
  )
}

export default Menu