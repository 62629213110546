import { Outlet } from "react-router-dom";

const FullScreenContent = () => {
  return (
    <div className="p-4">
      <Outlet />
    </div>
  )
};

export default FullScreenContent;
