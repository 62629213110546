import { useParams } from "react-router-dom";
import { useStrategyQuery } from "../../../hooks/useStrategies";
import { usePerformanceMetricsQuery } from "../../../hooks/useBacktest";
import { PerformanceMetricsResponseType, PerformanceMetricsType } from "../../../api/schema";
import ReactECharts from 'echarts-for-react'; 

export const SharpeValuesOverTime = () => {
    let { portfolioId } = useParams();
    const portfolioQuery = useStrategyQuery(portfolioId || "");
    const performanceMetricsQuery= usePerformanceMetricsQuery("admin", portfolioQuery.data?.["model-id"], portfolioQuery.data?.backtests[0]["backtest-id"]);
    if (performanceMetricsQuery.data) {
        const options = {
            tooltip: {
              trigger: 'axis'
            },
            legend: {
              data: ['1 year', '2 years', '3 years']
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            toolbox: {
              feature: {
                saveAsImage: {}
              }
            },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: ['1 year', '2 years', '3 years']
            },
            yAxis: {
              type: 'value'
            },
            series: 
            [
                "Alpha-Universe_LongOnly",
                "Alpha-Universe_LongShort",
                "Alpha-Universe_LongTheShort",
                "Alpha-Universe_Universe",
                "Beta-Universe_LongOnly",
                "Beta-Universe_LongShort",
                "Beta-Universe_LongTheShort",
                "Beta-Universe_Universe",
                "Sharpe_LongOnly",
                "Sharpe_LongShort",
                "Sharpe_LongTheShort",
                "Sharpe_Universe"
            ].map((metric: string) => {
                const data = (performanceMetricsQuery.data?.[metric as unknown as keyof PerformanceMetricsResponseType]);
                return {
                    name: metric,
                    type: 'line',
                    stack: 'Total',
                    data: [data["1 years"], data["2 years"], data["3 years"]]
                };
            })
          };
        return (
            <div>
                <ReactECharts option={options} />
            </div>
        );
    }

    if (performanceMetricsQuery.error) {
        return (
            <div>
                Error fetching performance metrics!
            </div>
        );
    }

    return (
        <div>Loading...</div>
    );
}
