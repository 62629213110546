import { get, post } from 'aws-amplify/api';
const login = async () => {
    const response = await fetch("https://test.analyticalplatform.com/api/token/", {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({"username":"jiri.fuchs@ccfr.cz","password":"Advcirkus69"})
    });
  
    const data = await response.json();
    localStorage.setItem('token', data.access);
  };
  
const getCall = async (apiName: string, path: string) => {
  const token = localStorage.getItem('token');
  const request = get({
    apiName,
    path,
    options: {
      headers: {
          'Authorization': `Bearer ${token}`
      }
    }
  });
  return request.response;
};

const postCall = async (apiName: string, path: string, body: any) => {
  const request = post({
    apiName,
    path,
    options: {
      body: body
    }
  });
  return request.response;
};
  
export const apiGetCall = async (apiName: string, path: string) => {
    let data
    try {
      const response = await getCall(apiName, path);
      data = await response.body.json();
    } catch (error: any) {
      if (error.$metadata.httpStatusCode === 401) {
        await login();
        const response = await getCall(apiName, path);
        data = await response.body.json();
      }
    }
  
    if (data) {
      return data;
    } else {
      throw new Error('Error fetching data');
    }
  };

  export const apiPostCall = async (apiName: string, path: string, body: any) => {
    let data
    try {
      const response = await postCall(apiName, path, body);
      data = await response.body.json();
    } catch (error: any) {
      if (error.$metadata.httpStatusCode === 401) {
        await login();
        const response = await getCall(apiName, path);
        data = await response.body.json();
      }
    }
  
    if (data) {
      return data;
    } else {
      throw new Error('Error fetching data');
    }
  };