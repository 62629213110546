import Avatar from "react-avatar"
import { OverlayTrigger, Popover } from "react-bootstrap"

export const User = () => {
  const popover = (
    <Popover id="popover-basic">
        <Popover.Body>
            <div className="p-2">Profile</div>
            <div className="p-2">Settings</div>
            <div className="p-2">Logout</div>
        </Popover.Body>
  </Popover>
)
  return (
    <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
      <Avatar initials={"MK"} round={true} size="2rem" value="MK"/>
    </OverlayTrigger>
  )
}
