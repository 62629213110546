import  Spinner from "react-bootstrap/Spinner"

const LoadingSpinner = () => {
  return (
    <Spinner animation="border" role="status" className="initial-spinner">
        <span className="visually-hidden">Loading Strategies...</span>
    </Spinner>
  )
}

export default LoadingSpinner;
