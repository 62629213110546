import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import { useBacktestReportQuery } from "../../../hooks/useBacktest"
import { useStrategyQuery } from "../../../hooks/useStrategies";
import { MetricsEnum, backtestReportItemSchema, metricsSchema } from "../../../api/schema";
import { useState } from "react";

export const PerformanceDetails = ({
    portfolioId
}: {
    portfolioId: string;
}) => {
    const strategyQuery = useStrategyQuery(portfolioId);
    const report = useBacktestReportQuery(strategyQuery.data?.["user-id"], strategyQuery.data?.["model-id"], strategyQuery.data?.backtests[0]["backtest-id"]);
    const [selectedMetric, setSelectedMetric] = useState<MetricsEnum>("LongOnly");

    if (report.data) {
        return (
            <div className="mt-4">
                <Row>
                    <ButtonGroup>
                        {
                            metricsSchema.options.map((option) => (
                                <Button key={option} active={selectedMetric === option} onClick={() => setSelectedMetric(option)}>{option}</Button>
                            ))
                        }                
                    </ButtonGroup>
                </Row>
                <Row>
                    {

                        ["Volatility", "Sharpe", "Alpha-Universe", "Alpha-SP500", "Beta-Universe", "Beta-SP500", "CAGR", "Max-Drawdown", "Average-Drawdown", "Win-Rate", "Skewness", "Kurtosis", "VaR95", "CVaR95", "Max-Drawdown-Duration", "Calmar", "Sterling", "Sortino"].map((metric) => {
                            const property = metric as unknown as string;
                            const a = report.data[selectedMetric] as any;
                            return (
                                
                                <Col xs={4} key={metric}>
                                    <div>{metric}:</div>
                                    <p>{a[property]}</p>
                                </Col>
                            );
                        })
                    }
                </Row>
            </div>
        );
    }

    if (report.error) {
        return (
            <div>
                Error fetching backtest performance!
            </div>
        );
    }

    return (
        <div>Loading...</div>
    )   
}
