import { useParams } from "react-router-dom";
import { useStrategyQuery } from "../../../hooks/useStrategies";
import { useRebalanceQuery } from "../../../hooks/useRebalances";
import { useState } from "react";
import { Row, Stack, Table } from "react-bootstrap";
import { toFixed } from "../../../utils/utils";
import Paginator from "../../common/Pagination";


const ReportDetails = () => {
    let { portfolioId } = useParams();
    const portfolioQuery = useStrategyQuery(portfolioId || "");
    const query = useRebalanceQuery(portfolioQuery.data?.PK, portfolioQuery.data?.["latest-report-id"]);
    const [page, setPage] = useState(0);
    
    if (query.data && Array.isArray(query.data)) {
        const orders = query.data;
        const pageSize = 20;
        const sortedOrders = orders.sort((a, b) => b.Value - a.Value);
        const filteredOrders = sortedOrders.slice((page) * pageSize, (page +1) * pageSize);
    
        return(<>
                <Row>
                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                <th>Ticker</th>
                                <th>value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredOrders.map((order) => {
                                return (
                                    <tr key={order.Ticker} >
                                        <th>{order.Ticker}</th>
                                        <th>{toFixed(order.Value)}</th>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                    <Stack direction="horizontal">
                        <div>
                            <Paginator pageSize={pageSize} items={orders.length} activePage={page} onPageChange={setPage}/>
                        </div>
                    </Stack>
                </Row>
            </>)
    }
    if (portfolioQuery.isError) {
        return (
          <div>
            Error fetching report!
          </div>
        )
      }
    
      
    return <div>Loading...</div>;
}

export default ReportDetails;
