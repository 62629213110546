import { useParams } from "react-router-dom";
import { useStrategyQuery } from "../../../hooks/useStrategies";
import { useRebalanceReturnsQuery } from "../../../hooks/useBacktest";
import ReactECharts from 'echarts-for-react'; 

const getMin = (data: {
    [key: string]: number
}) => {
    return Object.keys(data).map((k: string) => data[k] * 100).reduce((a, b) => Math.min(a, b), 0);
};

const getMax = (data: {
    [key: string]: number
}) => {
    return Object.keys(data).map((k: string) => data[k] * 100).reduce((a, b) => Math.max(a, b), 0);
};


const getDistribution = (data = {}) => {
    const min = getMin(data);
    const max = getMax(data);
    const binCount = 20;
    const binSize = (max - min) / binCount;
    const distribution = new Map<number, number>();
    for(let i = 0; i < binCount; i++) {
        distribution.set(min + binSize * i, 0);
    }
    
    const longShorts = data as {
        [key: string]: number
    };
    Object.keys(longShorts).map((k: string) => longShorts[k] * 100).map((v: number) => {
        for(let i = 0; i < binCount; i++) {
            if (v < min + binSize * (i + 1)) {
                distribution.set(min + binSize * i, (distribution.get(min + binSize * i) || 0) + 1);
                break;
            }
        }
    })

    let histogram = []
    for (let [key, value] of distribution.entries()) {
        histogram.push([key, value]);
     }

    return histogram; 
};

const getOptions = (data: any) => {
    return {
        xAxis: [
            {
              type: 'value',
              name: "Probability",
              axisTick: { show: false },
              axisLabel: { show: false },
              axisLine: { show: false }
            }
          ],
          yAxis: [
            {
              type: 'value',
              name: "Count",
            }
          ],
        series: [{
            name: 'Count',
            type: 'bar',
            barWidth: '100%',
            data: data,
            label: {
                show: true,
                position: "top"
            }
          }
        ],
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
      };
};

export const RebalanceReturns = () => {
  
    let { portfolioId } = useParams();
    const portfolioQuery = useStrategyQuery(portfolioId || "");
    const rebalanceReturnsQuery = useRebalanceReturnsQuery("admin", portfolioQuery.data?.["model-id"], portfolioQuery.data?.backtests[0]["backtest-id"]);

    

    if (rebalanceReturnsQuery.data) {
        const longShortsDistribution = getDistribution(rebalanceReturnsQuery.data.LongShort);
        const longsDistribution = getDistribution(rebalanceReturnsQuery.data.Longs);
        const shortsDistribution = getDistribution(rebalanceReturnsQuery.data.Shorts);
        const universeDistribution = getDistribution(rebalanceReturnsQuery.data.Universe);

        return (
            <div>
                <div>
                    <h3>Long Shorts Returns</h3>
                    <ReactECharts option={getOptions(longShortsDistribution)} />
                </div>
                <div className="mt-2">
                    <h3>Longs Returns</h3>
                    <ReactECharts option={getOptions(longsDistribution)} />
                </div>
                <div className="mt-2">
                    <h3>Shorts Returns</h3>
                    <ReactECharts option={getOptions(shortsDistribution)} />
                </div>
                <div className="mt-2">
                    <h3>Universe Returns</h3>
                    <ReactECharts option={getOptions(universeDistribution)} />
                </div>
            </div>
        );
    }

    if (rebalanceReturnsQuery.error) {
        return (
            <div> {rebalanceReturnsQuery.error.message}</div>
        );
    }


    return (
        <div>Loading...</div>
    );
}
