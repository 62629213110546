import { useNavigate, useParams } from "react-router-dom";
import { postBacktest } from "../../../api/api";
import { CreateBacktestForm, NewBacktest, createBacktestFormSchema } from "../../../api/schema";
import { useFactoryRankingQuery } from "../../../hooks/useFactors";
import { useStrategyQuery } from "../../../hooks/useStrategies";
import CustomForm from "../../common/FormElement/FormElement"
import { useMutation } from "react-query";

export const PortfolioSettings = () => {
  const {portfolioId} = useParams();
  const navigate = useNavigate();
  const strategyQuery = useStrategyQuery(portfolioId || "");
  const factorsQuery = useFactoryRankingQuery("admin", portfolioId);
  const updateBacktest = async (backtest: NewBacktest) => {
    const newBacktest = await postBacktest("9dcac14e-585c-4e72-ab8f-42177f11078f", portfolioId ||  "", backtest)
    navigate(`/${newBacktest["strategy-id"]}`);
  };
  const mutation = useMutation({
      mutationFn: updateBacktest
  });
  const onSubmit = async (backtest: CreateBacktestForm) => {
      await mutation.mutate({
          ...backtest,

          "user-id": "admin",
          "strategy-id": portfolioId || "",
          "long-only": true,
          "model-id": strategyQuery.data?.["model-id"] || "",
      });
  };


  if (factorsQuery.data) {
    if (strategyQuery.data) {
    const factors = factorsQuery.data;
    const backtest = strategyQuery.data.backtests[0];
    return (
      <>
              <CustomForm schema={createBacktestFormSchema} onSubmit={onSubmit}>
                  <CustomForm.Group name="factor" >
                      <CustomForm.Label>Model</CustomForm.Label>
                      <CustomForm.Select defaultValue={factors[0].Factor} options={factors}  />
                      <CustomForm.Error/>
                  </CustomForm.Group>
  
                  <CustomForm.Group name="start-cash">
                      <CustomForm.Label>Start Cash</CustomForm.Label>
                      <CustomForm.InputGroup>
                          <CustomForm.Element type="number" defaultValue={10000} />
                          <CustomForm.InputText>USD</CustomForm.InputText>
                      </CustomForm.InputGroup>
                      <CustomForm.Error/>
                  </CustomForm.Group>
  
                  <CustomForm.Group name="backtest-period">
                      <CustomForm.Label>Backtest Period</CustomForm.Label>
                      <CustomForm.Element type="date" defaultValue={backtest["backtest-period"]}  />
                      <CustomForm.Error/>
                  </CustomForm.Group> 
                  <CustomForm.Group name="n-simulations">
                      <CustomForm.Label>Number of simulations</CustomForm.Label>
                      <CustomForm.Element type="number" defaultValue={backtest["n-simulations"]} />
                      <CustomForm.Error/>
                  </CustomForm.Group>
  
                  <CustomForm.Group name="number-of-stocks">
                      <CustomForm.Label>Number of stocks</CustomForm.Label>
                      <CustomForm.Element type="number" defaultValue={backtest["number-of-stocks"]} />
                      <CustomForm.Error/>
                  </CustomForm.Group>
  
                  <CustomForm.Group name="exposure">
                      <CustomForm.Label>Exposure</CustomForm.Label>
                      <CustomForm.Element type="number" defaultValue={backtest.exposure} />
                      <CustomForm.Error/>
                  </CustomForm.Group>
  
                  <CustomForm.Group name="leverage">
                      <CustomForm.Label>Leverage</CustomForm.Label>
                      <CustomForm.Element type="number" defaultValue={backtest.leverage} />
                      <CustomForm.Error/>
                  </CustomForm.Group>

                  <CustomForm.Group name="rolling-window">
                      <CustomForm.Label>Rolling Window</CustomForm.Label>
                      <CustomForm.Element type="number" defaultValue={1} />
                      <CustomForm.Error/>
                  </CustomForm.Group>

                  <CustomForm.Group name="simul-random-backtest">
                      <CustomForm.CheckBox defaultValue={backtest["simul-random-backtest"]} label="Is random simulation" />
                      <CustomForm.Error/>
                  </CustomForm.Group>
                  
                  <CustomForm.Submit>Update</CustomForm.Submit>
              </CustomForm>
      </>
    )
    }
  }

  if (strategyQuery.error) {
    return (
      <div>Error: {strategyQuery.error.message}</div>
    )
  }

  if (factorsQuery.error) {
    return (
      <div>Error: {factorsQuery.error.message}</div>
    )
  }

  return (
    <div>Loading...</div>
  )
  
}
