import { z } from 'zod';

export const backtestSchema = z.object({
    "backtest-id": z.string(),
    "backtest-period": z.string(),
    "n-simulations": z.number().min(0).max(100, "Max 100 simulations"),
    "number-of-stocks": z.number().min(0).max(100),
    "simul-random-backtest": z.boolean(),
    "exposure": z.number().min(0, "Zero").max(2),
    "leverage": z.number().min(1).max(2)
});
export type BacktestType = z.infer<typeof backtestSchema>;  

export const backtestChartItemSchema = z.object({
    date: z.string(),
    LongOnly: z.number(),
    LongTheShort: z.number(),
    LongShort: z.number(),
    Universe: z.number(),
    SP500: z.number(),

});
export const backtestChartItemsSchema = z.array(backtestChartItemSchema);
export const backtestChartApiResponse = z.object({
        Date: z.record(z.string(), z.string()),
        LongOnly: z.record(z.string(), z.number()),
        LongTheShort: z.record(z.string(), z.number()),
        LongShort: z.record(z.string(), z.number()),
        Universe: z.record(z.string(), z.number()),
        SP500: z.record(z.string(), z.number())
});
export type BacktestChartItem = z.infer<typeof backtestChartItemSchema>;
export type BacktestChartApiResponse = z.infer<typeof backtestChartApiResponse>;
export type MetricsEnum = z.infer<typeof metricsSchema>;

export const backtestReportItemSchema = z.object({
    "Final-Equity": z.number(),
    "Volatility": z.number(),
    "Sharpe": z.number(),
    "Alpha-Universe": z.number(),
    "Alpha-SP500": z.number(),
    "Beta-Universe": z.number(),
    "Beta-SP500": z.number(),
    "CAGR": z.number(),
    "Max-Drawdown": z.number(),
    "Average-Drawdown": z.number(),
    "Win-Rate": z.number(),
    "Skewness": z.number(),
    "Kurtosis": z.number(),
    "VaR95": z.number(),
    "CVaR95": z.number(),
    "Max-Drawdown-Duration": z.number(),
    "Calmar": z.number(),
    "Sterling": z.number(),
    "Sortino": z.number()
});


export const metricsSchema = z.enum(["LongOnly", "LongTheShort", "LongShort", "Universe", "SP500"]);


export const backtestReportSchema = z.record(metricsSchema, backtestReportItemSchema);
export const newBacktestSchema = z.object({
    "start-cash": z.number(),
    "factor": z.string(),
    "backtest-period": z.string(),
    "number-of-stocks": z.number(),
    "simul-random-backtest": z.boolean(),
    "n-simulations": z.number(),
    "leverage": z.number(),
    "exposure": z.number(),
    "user-id": z.string(),
    "strategy-id": z.string(),
    "long-only": z.boolean(),
    "model-id": z.string(),
    "rolling-window": z.number()
});

export const createBacktestFormSchema = z.object({
    "start-cash": z.number(),
    "factor": z.string(),
    "backtest-period": z.string(),
    "number-of-stocks": z.number(),
    "simul-random-backtest": z.boolean(),
    "n-simulations": z.number(),
    "leverage": z.number(),
    "exposure": z.number(),
    "rolling-window": z.number(),
});
export type CreateBacktestForm = z.infer<typeof createBacktestFormSchema>;

export type NewBacktest = z.infer<typeof newBacktestSchema>;
export type BacktestReportItem = z.infer<typeof backtestReportItemSchema>;
export type BacktestReportResponse = z.infer<typeof backtestReportSchema>;

export const createBacktestSchema = z.object({
    "user-id": z.string(),
    "model-id": z.string(),
    "features": z.record(z.string(), z.number()),
    "strategy-id": z.string(),
    "pool": z.string(),
    "frequency": z.string(),
    "backtest-period": z.string(),
    "number-of-stocks": z.number(),
    "long-only": z.boolean(),
    "simul-random-backtest": z.boolean(),
    "n-simulations": z.number(),
    "exposure": z.number(),
    "leverage": z.number(),
    "status": z.enum(["success", "failure"])
});

const createBacktestResponseSchema = z.object({
    "results": createBacktestSchema
});
export type CreateBacktestType = z.infer<typeof createBacktestResponseSchema>;
export type CreateBacktestResponseType = z.infer<typeof createBacktestResponseSchema>;


export const strategySchema = z.object({
    "PK": z.string(),
    "strategy-name": z.string(),
    "backtests": z.array(backtestSchema),
    "broker-api-key": z.string().optional(),
    "broker-paper": z.boolean().optional(),
    "broker-secret-key": z.string().optional(),
    "deployed": z.boolean().optional(),
    "deployedBacktestId": z.string().optional(),
    "hyperparameters": z.object({}).optional(),
    "image": z.string(),
    "instance-count-processing": z.number(),
    "instance-count-training": z.number(),
    "instance-type-processing": z.string(),
    "instance-type-training": z.string(),
    "latest-report-id": z.string().optional(),
    
    "max-runtime": z.number(),
    "model-id": z.string(),
    "pool": z.string(),
    "reports": z.array(z.object({
        "actual-orders-path": z.string(),
        "calculated": z.string(),
        "exposure": z.number(),
        "leverage": z.number(),
        "path": z.string(),
        "report-id": z.string(),
    })).optional(),
    "static-hyperparameters": z.object({
        "predictor_type": z.string()
    }).optional(),
    "train-period-end": z.string(),
    "train-period-start": z.string(),
    "user-id": z.string(),
    "validation-period-end": z.string()
});
export const strategyListSchema = z.array(strategySchema);
export type StrategyObject = z.infer<typeof strategySchema>;


export const orderSchema = z.object({
    "symbol": z.string(),
    "score": z.number(),
    "current_value": z.number(),
    "current_qty": z.number(),
    "order_value": z.number(),
    "order_qty": z.number(),
    "final_value": z.number(),
    "final_qty": z.number(),
    "current_asset_price": z.number(),
    "liquidate": z.boolean()
});
export type OrderObject = z.infer<typeof orderSchema>;

export const reportSchema = z.object({
    "Ticker": z.string(),
    "Value": z.number()
});

export type ReportObject = z.infer<typeof reportSchema>;

export const rebalanceSchema = z.object({
    "balance": z.number(),
    "message": z.string(),
    "value_per_long": z.number(),
    "value_per_short": z.number(),
    "leverage": z.number(),
    "exposure": z.number(),
    "nr_longs": z.number(),
    "nr_short": z.number(),
    "non_tradable": z.array(z.string()),
    "nr_untraded_shorts": z.number(),
    "too_expensive_shorts": z.array(z.string()),
    orders: z.array(orderSchema)
});

export type RebalanceObject = z.infer<typeof rebalanceSchema>;

export const rebalanceAPIResponseSchema = z.object({
    "results": rebalanceSchema.or(z.array(reportSchema))
});
export type RebalanceAPIResponseType = z.infer<typeof rebalanceAPIResponseSchema>;

export const factorSchema = z.object({
    "Feature": z.string(),
    "Bucket": z.string(),
    "Description": z.string(),
});
export type FactorType = z.infer<typeof factorSchema>;

export const factorRankingItemSchema = z.object({
    "Factor": z.string(),
    "T-Stat": z.number(),
    "P-Value": z.number(),
    "Direction": z.number(),
});
export const factorRankingSchema = z.array(factorRankingItemSchema);
export type FactorRankingItem = z.infer<typeof factorRankingItemSchema>;

export const factorMomentumItemSchema = z.object({
    "Date": z.number(),
    "Factor": z.string(),
    "Log_Return_Q1": z.number(),
    "Log_Return_Q2": z.number(),
    "Log_Return_Q3": z.number(),
    "Log_Return_Q4": z.number(),
    "Log_Return_Q5": z.number(),
});
   
export const factorMomentumSchema = z.object({
  "results":  z.array(factorMomentumItemSchema)
});

export type FactorMomentumResponse = z.infer<typeof factorMomentumSchema>;
export type FactorMomentumItem = z.infer<typeof factorMomentumItemSchema>;

export const factorCumulativeReturnItemSchema = z.object({
    "Date": z.number(),
    "AR1_LogPrice_252": z.number(),
    "Autocovariance_252": z.number(),
    "CCI_126": z.number(),
    "CapExToMarketCap_Position_125": z.number(),
    "DX_252": z.number(),
    "DX_504": z.number(),
    "PLUS_DM_2": z.number(),
    "PriceCashFlowRatio_Change_252": z.number(),
    "Price_Position_3": z.number(),
    "SUV_Coef_8_weeks": z.number(),
    "SUV_Coef_Diff_8_weeks": z.number(),
    "Skewness_252": z.number(),
    "Smooth_22_Volume_ROC_252": z.number(),
    "Smooth_5_Volume_MOM_22": z.number(),
    "WMA_2": z.number(),
});

export const factorCumulativeReturnSchema = z.object({
    "results": z.array(factorCumulativeReturnItemSchema)
});

export type FactorCumulativeReturnItem = z.infer<typeof factorCumulativeReturnItemSchema>;
export type FactorCumulativeReturnResponse = z.infer<typeof factorCumulativeReturnSchema>;


export const rebalanceReturnsSchema = z.object({
    "Longs": z.record(z.string(), z.number()).optional(),
    "PickedLongs": z.record(z.string(), z.string()),
    "PickedShorts": z.record(z.string(), z.string()),
    "Shorts": z.record(z.string(), z.number()).optional(),
    "LongShort": z.record(z.string(), z.number()).optional(),
    "Universe": z.record(z.string(), z.number()).optional()
});
export const rebalanceReturnsResponseSchema = z.object({
    "results": rebalanceReturnsSchema
});

export type RebalanceReturnsType = z.infer<typeof rebalanceReturnsSchema>;
export type RebalanceReturnsResponseType = z.infer<typeof rebalanceReturnsResponseSchema>;


export const rollingStatsSchema = z.object({
    "strategy": z.string(),
    "max_percent": z.number(),
    "min_percent": z.number(),
    "fail_rate": z.number()
});
export const rollingStatsListSchema = z.array(rollingStatsSchema);

export const rollingStatsResponseSchema = z.object({
    "results": z.array(rollingStatsSchema)
});

export type RollingStatsType = z.infer<typeof rollingStatsSchema>;
export type RollingStatsListType = z.infer<typeof rollingStatsListSchema>;
export type RollingStatsResponseType = z.infer<typeof rollingStatsResponseSchema>;

export const factorMeanQuantileSchema = z.object({
    "Log_Return_Q1": z.number(),
    "Log_Return_Q2": z.number(),
    "Log_Return_Q3": z.number(),
    "Log_Return_Q4": z.number(),
    "Log_Return_Q5": z.number()
});
export const factorMeanQuantileResponseSchema = z.record(z.string(), factorMeanQuantileSchema);

export type FactorMeanQuantileType = z.infer<typeof factorMeanQuantileSchema>;
export type FactorMeanQuantileResponseType = z.infer<typeof factorMeanQuantileResponseSchema>;

export const drawdownSchema = z.object({
    "LongOnly": z.record(z.string(), z.number()),
    "LongShort": z.record(z.string(), z.number()),
    "LongTheShort": z.record(z.string(), z.number()),
    "Universe": z.record(z.string(), z.number())
});
export type DrawdownType = z.infer<typeof drawdownSchema>;

export const performanceMetricsSchema = z.object({
    "1 years": z.number(),
    "2 years": z.number(),
    "3 years": z.number()
});
export const performanceMetricsResponseSchema = z.object({
    "Alpha-Universe_LongOnly": performanceMetricsSchema,
    "Alpha-Universe_LongShort": performanceMetricsSchema,
    "Alpha-Universe_LongTheShort": performanceMetricsSchema,
    "Alpha-Universe_Universe": performanceMetricsSchema,
    "Beta-Universe_LongOnly": performanceMetricsSchema,
    "Beta-Universe_LongShort": performanceMetricsSchema,
    "Beta-Universe_LongTheShort": performanceMetricsSchema,
    "Beta-Universe_Universe": performanceMetricsSchema,
    "Sharpe_LongOnly": performanceMetricsSchema,
    "Sharpe_LongShort": performanceMetricsSchema,
    "Sharpe_LongTheShort": performanceMetricsSchema,
    "Sharpe_Universe": performanceMetricsSchema
});

export type PerformanceMetricsType = z.infer<typeof performanceMetricsSchema>;
export type PerformanceMetricsResponseType = z.infer<typeof performanceMetricsResponseSchema>;


export const performanceTimelineSchema = z.record(z.string(), z.number().nullable());
export const performanceTimelineResponseSchema = z.object({
    "Beta_universe_LongOnly": performanceTimelineSchema,
    "Alpha_universe_LongOnly": performanceTimelineSchema,
    "Beta_universe_LongShort": performanceTimelineSchema,
    "Alpha_universe_LongShort": performanceTimelineSchema,
    "Beta_universe_LongTheShort": performanceTimelineSchema,
    "Alpha_universe_LongTheShort": performanceTimelineSchema,
    "Beta_universe_Universe": performanceTimelineSchema,
    "Alpha_universe_Universe": performanceTimelineSchema,
    "Sharpe_LongOnly": performanceTimelineSchema,
    "Sharpe_LongShort": performanceTimelineSchema,
    "Sharpe_LongTheShort": performanceTimelineSchema,
    "Sharpe_Universe": performanceTimelineSchema

});

export type PerformanceTimelineType = z.infer<typeof performanceTimelineSchema>;
export type PerformanceTimelineResponseType = z.infer<typeof performanceTimelineResponseSchema>;