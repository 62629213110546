import ReactECharts from 'echarts-for-react'; 
import { useBacktestTimeseriesQuery } from '../../hooks/useBacktest';
import { Col, Placeholder, Row } from 'react-bootstrap';
import { useStrategyQuery } from '../../hooks/useStrategies';
import DateRangeSelector, { DateRangeOptions, firstDateOfRange } from '../common/DateRangeSelector';
import { useState } from 'react';
import moment from 'moment';
import PortfolioIndicator from '../common/PortfolioIndicator';


export const BacktestChart = ({
  portfolioId
}: {
  portfolioId: string;
}) => {
    const portfolioQuery = useStrategyQuery(portfolioId);
    
    const defaultDateRange = DateRangeOptions.OneYear;
    const query = useBacktestTimeseriesQuery(portfolioQuery.data?.['user-id'], portfolioQuery.data?.['model-id'], portfolioQuery.data?.backtests[0]["backtest-id"]);
    const [selectedRangeOption, setSelectedRangeOption] =useState<Date>(firstDateOfRange[defaultDateRange]());
    
    if (query.data) {
      const timeseries = query.data.filter((point: any) => moment(point.date).isAfter(selectedRangeOption));
      const options = {
        xAxis: {
          type: 'time',
          axisLabel: {
            formatter: (function(value: number){
              const date = new Date(value);
              return [date.getFullYear(), date.getMonth() + 1, date.getDate()].join('-');
            })
          }
        },
        yAxis: {
          type: 'value',
          name: 'Portfolio Value'
        },
        series: ["LongOnly", "LongTheShort", "LongShort", "Universe", "SP500"].map((type) => {
          return {
            data: timeseries.map((point: any) => [new Date(point.date), point[type]]),
            type: 'line',
            name: type,
          }
        }),
        tooltip: {
          trigger: 'axis',
        },
      };
      return (<div>
        <Row>
          <Col xl={6} xxl={7}>
            <PortfolioIndicator amount={10042.15} currency='EUR'/>
          </Col>
          <Col xxl={5}>
            <DateRangeSelector defaultDateRange={defaultDateRange} onChange={setSelectedRangeOption} />

          </Col>
        </Row>
        <ReactECharts option={options} />
      </div>);
    }

    if (query.isError) {
        return <div>Error fetching backtest chart!</div>;
    }

    return (
      <Placeholder as="div" animation="glow" >
          <Placeholder xs={12} style={{
            height: "300px"
          }}/>
      </Placeholder>
    );      
}
