import { useParams } from "react-router-dom";
import { useStrategyQuery } from "../../../hooks/useStrategies";
import { FactorTable } from "./FactorTable";
import { MomentumChart } from "./MomentumChart";
import { CumulativeReturnChart } from "./CumulativeReturnChart";
import { Col, Row } from "react-bootstrap";
import { LogReturnsByQuarter } from "./LogReturnsByQuarter";
import { SharpeValuesOverTime } from "./SharpeValuesOverTime";
import { DrawdownChart } from "./DrawdownChart";
import { PerformanceTimelineChart } from "./PerformanceTimelineChart";

const FactorsOverview = () => {
    let { portfolioId } = useParams();
    const portfolioQuery = useStrategyQuery(portfolioId || "");
    
    if (portfolioQuery.data) {
        return (
            <Row>
              <Col sm={6}>
                <Row>
                  <h3>Returns</h3>
                    <CumulativeReturnChart />
                </Row>
                <Row className="my-4">
                  <h3>Momentum</h3>
                  <MomentumChart />
                </Row>
                <Row className="my-4">
                  <h3>Log Returns by Quarter</h3>
                  <LogReturnsByQuarter />
                </Row>
                <Row className="my-4">
                  <h3>Sharpe Values Over Time</h3>
                  <SharpeValuesOverTime />
                </Row>
                <Row className="my-4">
                  <h3>Strategies Drawdown</h3>
                  <DrawdownChart />
                </Row>
                <Row className="my-4">
                  <h3>Beta, Alpha and Sharpe over Time</h3>
                  <PerformanceTimelineChart />
                </Row>
              </Col>
              <Col sm={6}>
                <FactorTable portfolioId={portfolioQuery.data?.PK} userId={portfolioQuery.data?.["user-id"]}/>
              </Col>
            </Row>
        );  
    }

    if (portfolioQuery.isError) {
        return (
          <div>
            Error fetching strategies!
          </div>
        )
      }
    
      
    return <div>Loading...</div>;
}

export default FactorsOverview;
