import { Col, Row, Stack, Table } from "react-bootstrap";
import { ReportObject } from "../../../api/schema";
import Paginator from "../../common/Pagination";
import { useState } from "react";
import { toFixed } from "../../../utils/utils";
import ReportPieChart from "./ReportPieChart";
import { MdNavigateNext } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { RebalanceReturns } from "./RebalanceReturns";



export const ReportTable = ({
    orders,
    portfolioId
}: {
    portfolioId: string;
    orders: ReportObject[];
}) => {
    const [page, setPage] = useState(0);
    const sortedOrders = orders.sort((a, b) => b.Value - a.Value);
    const filteredOrders = sortedOrders.slice((page) * 5, (page +1) * 5);
    const navigate = useNavigate();

    return (
        <Row>
            <Col>
                <ReportPieChart orders={orders}/>
            </Col>
            <Col>
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Ticker</th>
                            <th>value</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredOrders.map((order) => {
                            return (
                                <tr key={order.Ticker} onClick={() => {navigate(`/${portfolioId}/report`)}} style={{
                                    cursor: "pointer"
                                }}>
                                    <th>{order.Ticker}</th>
                                    <th>{toFixed(order.Value)}</th>
                                    <th style={{
                                        paddingLeft: "0px",
                                        paddingRight: "0px"
                                    }}><MdNavigateNext /></th>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
                <Stack direction="horizontal">
                    <div>
                        <Paginator items={orders.length} activePage={page} onPageChange={setPage}/>
                    </div>
                </Stack>
            </Col>
        </Row>
    );
}
