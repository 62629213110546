import { useQuery } from "react-query";
import { fetchStrategies } from "../api/api";
import { StrategyObject } from "../api/schema";

export const useStrategiesQuery = <TData = StrategyObject[]>(select?: (strategies: StrategyObject[]) => TData) => useQuery<StrategyObject[], Error, TData>({
    queryKey: ['strategies'],
    queryFn: () => fetchStrategies(),
    select
  });

export const useStrategyQuery = (strategyId: string) => useStrategiesQuery<StrategyObject>((strategies) => strategies.find((strategy) => strategy.PK === strategyId) as StrategyObject);
