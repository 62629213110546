import { OverlayTrigger, Placeholder, Table, Tooltip } from "react-bootstrap"
import { useFactoryRankingQuery } from "../../../hooks/useFactors"
import { useState } from "react";
import Paginator from "../../common/Pagination";
import { toFixed } from "../../../utils/utils";

export const FactorTable = ({
    userId,
    portfolioId
}: {
    userId: string;
    portfolioId: string;
}) => {
    const query = useFactoryRankingQuery(userId, portfolioId);
    const [page, setPage] = useState(0);
    const pageSize = 50;

    if (query.data) {
        const factors = query.data.sort((a, b) => a["P-Value"] - b["P-Value"]);
        const filteredFactors = factors.slice((page) * pageSize, (page +1) * pageSize);

        return (
            <>
                <h3>Factor Importance</h3>
                <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Factor</th>
                        <th>P_Value</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredFactors.map((row, index) => (
                        <tr key={index}>
                            <td>{row.Factor}</td>
                            <td>
                                <OverlayTrigger placement="top" overlay={<Tooltip>{row["P-Value"]}</Tooltip>}>
                                    <span>{toFixed(row["P-Value"], 6)}</span>
                                </OverlayTrigger>    
                            </td>
                        </tr>
                    ))}
                </tbody>
                </Table>
                <Paginator pageSize={pageSize} items={factors.length} activePage={page} onPageChange={setPage}/>
            </>
        );
    }

    if (query.error) {
        return (
            <div>
                Error fetching factor ranking!
            </div>
        );
    }

    return (
        <>
            <h3>Factor Importance</h3>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Factor</th>
                        <th>P_Value</th>
                    </tr>
                </thead>
                <tbody>
                    {Array.from({ length: 13 }).map((_, index) => (
                        <tr key={index}>
                            <Placeholder as="td" animation="glow">
                                <Placeholder xs={12} />
                            </Placeholder>
                            <Placeholder as="td" animation="glow">
                                <Placeholder xs={12} />
                            </Placeholder>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    );
}
