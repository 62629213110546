import { useQuery } from "react-query";
import { fetchFactorCumulativeReturn, fetchFactorMomentum, fetchFactorRanking } from "../api/api";
import { FactorCumulativeReturnItem, FactorMomentumItem, FactorRankingItem } from "../api/schema";

export const useFactoryRankingQuery = <TData = FactorRankingItem[]>(userId?: string, portfolioId?: string, select?: (strategies: FactorRankingItem[]) => TData) => useQuery<FactorRankingItem[], Error, TData>({
    queryKey: ['factorRanking', userId, portfolioId],
    queryFn: () => fetchFactorRanking(userId || "", portfolioId || ""),
    select,
    enabled: !!userId && !!portfolioId
  });

export const useFactorMomentumQuery = <TData = FactorMomentumItem[]>(userId?: string, strategyId?: string, factor?: string, select?: (strategies: FactorMomentumItem[]) => TData) => useQuery<FactorMomentumItem[], Error, TData>({
  queryKey: ['factorMomentum', userId, strategyId, factor],
  queryFn: () => fetchFactorMomentum(userId || "", strategyId || "", factor || ""),
  select,
  enabled: !!userId && !!strategyId && !!factor
});

export const useFactorCumulativeReturnQuery = <TData = FactorCumulativeReturnItem[]>(userId?: string, strategyId?: string, factor?: string, select?: (strategies: FactorCumulativeReturnItem[]) => TData) => useQuery<FactorCumulativeReturnItem[], Error, TData>({
  queryKey: ['factorCumulativeReturn', userId, strategyId],
  queryFn: () => fetchFactorCumulativeReturn(userId || "", strategyId || ""),
  select,
  enabled: !!userId && !!strategyId
});

