import { useNavigate } from "react-router-dom";
import { StrategyObject } from "../../../api/schema";
import { useStrategiesQuery } from "../../../hooks/useStrategies";
import LoadingSpinner from "./LoadingSpinner";
import { ListGroup } from "react-bootstrap";


const PortfolioList = () => {
    const query = useStrategiesQuery();
    const navigate = useNavigate();

    if (query.data) {
        if(query.data.length === 1) {
            navigate(`/${query.data[0].PK}`);
        }

        return (
            <ListGroup>
                     {query.data?.map((strategy: StrategyObject) => (
                         <ListGroup.Item action href={strategy.PK} key={strategy.PK}>
                            {strategy.PK}
                        </ListGroup.Item>
                    ))}
              </ListGroup>
        );  
    }

    if (query.isError) {
        return (
          <div>
            Error fetching strategies!
          </div>
        )
      }
    
      
    return <LoadingSpinner />;
}

export default PortfolioList;
