import Button from "react-bootstrap/Button";
import { BacktestType, CreateBacktestForm, FactorRankingItem, createBacktestFormSchema } from "../../../api/schema";
import CustomForm from "../../common/FormElement/FormElement";
import { useNavigate, useParams } from "react-router-dom";
import TextInput from "../../common/TextInput";
import TextSelect from "../../common/TextSelect";
import TextNumberInput from "../../common/TextNumberInput";
import { Row } from "react-bootstrap";
import { useState } from "react";


const BacktestSettingsForm = ({
    backtest,
    factors,
    handleClose,
    onSubmit
}: {
    backtest: BacktestType;
    factors: FactorRankingItem[];
    handleClose?: () => void;
    onSubmit?: (backtest: CreateBacktestForm) => Promise<void>;
}) => {
    let { portfolioId } = useParams();
    const navigate = useNavigate();
    const [editable, setEditable] = useState(false);
    const onEditPres = (path: string) => {
        navigate(path);
    };

    if (!backtest && !factors) {
        return <div>Loading...</div>;
    }

        const update = async (data: CreateBacktestForm, e: any) => {
            e.preventDefault();
            
            if (onSubmit) {
                await onSubmit(data);
            }

            if (handleClose) {
                handleClose();
            }
        };
        return (
            // <>
            // <Row><h3>Investing Strategy</h3></Row>
            // <div style={{
            //     textAlign: "left",
            // }}>
            //     <div>Built on model: <TextSelect options={["PLUS_DM_4", "ATR_2", "EMA_22"]} defaultValue="PLUS_DM_4" editable={editable}/> </div>
            //     <div>The portfolio consist of the top <TextNumberInput defaultValue={10} editable={editable}/> assets ranked by the model </div>
            //     <div><TextSelect options={["Don't use", "Use"]} defaultValue="Don't use" editable={editable} /> the bottom 10 assets from the rank as a portfolio hedge</div>
            //     <div>The strategy is using leverage <TextNumberInput defaultValue={1.5} editable={editable}/> for the invested capital </div>
            // </div>
            // <Row className="mt-4">
            //     <Button onClick={() => setEditable(!editable)}>{editable ? "Cancel" : "Edit"}</Button>
            // </Row>
            // </>
            <CustomForm schema={createBacktestFormSchema} onSubmit={update}>
                <CustomForm.Group name="factor" >
                    <CustomForm.Label>Model</CustomForm.Label>
                    <CustomForm.Select defaultValue="1" options={factors} disabled  />
                    <CustomForm.Error/>
                </CustomForm.Group>

                <CustomForm.Group name="amount">
                    <CustomForm.Label>Initial amount</CustomForm.Label>
                    <CustomForm.InputGroup>
                        <CustomForm.Element type="number" defaultValue={10000} disabled />
                        <CustomForm.InputText>USD</CustomForm.InputText>
                    </CustomForm.InputGroup>
                    <CustomForm.Error/>
                </CustomForm.Group>

                <CustomForm.Group name="backtest-period">
                    <CustomForm.Label>Backtest Period</CustomForm.Label>
                    <CustomForm.Element type="date" defaultValue={backtest["backtest-period"]} disabled  />
                    <CustomForm.Error/>
                </CustomForm.Group> 
                <CustomForm.Group name="n-simulations">
                    <CustomForm.Label>Number of simulations</CustomForm.Label>
                    <CustomForm.Element type="number" defaultValue={backtest["n-simulations"]} disabled />
                    <CustomForm.Error/>
                </CustomForm.Group>

                <CustomForm.Group name="number-of-stocks">
                    <CustomForm.Label>Number of stocks</CustomForm.Label>
                    <CustomForm.Element type="number" defaultValue={backtest["number-of-stocks"]} disabled />
                    <CustomForm.Error/>
                </CustomForm.Group>

                <CustomForm.Group name="exposure">
                    <CustomForm.Label>Exposure</CustomForm.Label>
                    <CustomForm.Element type="number" defaultValue={backtest.exposure} disabled />
                    <CustomForm.Error/>
                </CustomForm.Group>

                <CustomForm.Group name="leverage">
                    <CustomForm.Label>Leverage</CustomForm.Label>
                    <CustomForm.Element type="number" defaultValue={backtest.leverage} disabled />
                    <CustomForm.Error/>
                </CustomForm.Group>
                <CustomForm.Group name="simul-random-backtest">
                    <CustomForm.CheckBox defaultValue={backtest["simul-random-backtest"]} label="Is random simulation" disabled />
                    <CustomForm.Error/>
                </CustomForm.Group>
                
                {onSubmit && <CustomForm.Submit>Update</CustomForm.Submit>}
                
                <Button className="m-2" onClick={() => onEditPres(`/${portfolioId}/factors`)}>Edit Model</Button>
                <Button className="m-2" onClick={() => onEditPres(`/${portfolioId}/settings`)}>Edit Strategy</Button>
            </CustomForm>
          );
}

export default BacktestSettingsForm;