import { Col, Row } from "react-bootstrap"
import { BacktestChart } from "../../backtest/BacktestChart"
import ReportOverview from "./ReportOverview"
import { useParams } from "react-router-dom";
import BacktestSettingsForm from "./BacktestSettingsForm";
import { useStrategyQuery } from "../../../hooks/useStrategies";
import { useFactoryRankingQuery } from "../../../hooks/useFactors";
import { PerformanceDetails } from "./PerformanceDetails";
import { RebalanceReturns } from "./RebalanceReturns";
import { RollingStatsTable } from "./RollingStatsTable";

export const PortfolioDetail = () => {
  let { portfolioId = "" } = useParams() ;
  const strategyQuery = useStrategyQuery(portfolioId);
  const factorsQuery = useFactoryRankingQuery("admin", strategyQuery.data?.PK);


  if (strategyQuery.data && factorsQuery.data) {
    return (
      <>
      <Row>
        <h1>{strategyQuery.data["strategy-name"]}</h1>
      </Row>
      <Row>
          <Col md={8} sm={12}>
            <div className="p-3">
                {/* <ModelDetails strategyId={strategy.PK} deployedBacktest={strategy.deployedBacktestId}/> */}
                <BacktestChart portfolioId={portfolioId} />
                <ReportOverview portfolioId={portfolioId} />
                <PerformanceDetails portfolioId={portfolioId}/>
                <RebalanceReturns />
                <RollingStatsTable portfolioId={portfolioId}/>
            </div>
          </Col>
          <Col md={4}>
              <BacktestSettingsForm factors={factorsQuery.data} backtest={strategyQuery.data.backtests[0]}/>
          </Col>
      </Row>
      </>
    )
  }

  return (
    <div>Loading...</div>
  )

  
}
