import { useState } from "react";
import { Button, ButtonGroup, ButtonToolbar } from "react-bootstrap";
import moment from "moment";

export enum DateRangeOptions {
    OneDay = "1 D",
    FiveDays = "5 D",
    OneMonth = "1 M",
    SixMonths = "6 M",
    YearToDate = "YTD",
    OneYear = "1 Y",
    FiveYears = "5 Y",
    Max = "Max"
};

export const firstDateOfRange = {
    [DateRangeOptions.OneDay]: () => moment().subtract(1, 'day').toDate(),
    [DateRangeOptions.FiveDays]: () => moment().subtract(5, 'day').toDate(),
    [DateRangeOptions.OneMonth]: () => moment().subtract(1, 'month').toDate(),
    [DateRangeOptions.SixMonths]: () => moment().subtract(6, 'month').toDate(),
    [DateRangeOptions.YearToDate]: () => moment().startOf('year').toDate(),
    [DateRangeOptions.OneYear]: () => moment().subtract(1, 'year').toDate(),
    [DateRangeOptions.FiveYears]: () => moment().subtract(5, 'year').toDate(),
    [DateRangeOptions.Max]: () => moment('2000-01-01').toDate()
};


const DateRangeSelector = ({
    defaultDateRange = DateRangeOptions.SixMonths,
    onChange
}: {
    defaultDateRange?: DateRangeOptions
    onChange?: (dateRange: Date) => void
}) => {
    const [activeOptions, setActiveOptions] = useState(defaultDateRange);
    const onButtonClick = (dateRange: DateRangeOptions) => {
        setActiveOptions(dateRange);
        if (onChange) {
            onChange(firstDateOfRange[dateRange]());
        }
    };

  return (
    <ButtonToolbar aria-label="Toolbar with button groups">
        <ButtonGroup className="me-2" aria-label="First group">
            <Button 
                active={activeOptions === DateRangeOptions.OneDay}
                onClick={() => onButtonClick(DateRangeOptions.OneDay)}>
                    1 D
            </Button>
            <Button active={activeOptions === DateRangeOptions.FiveDays} onClick={() => onButtonClick(DateRangeOptions.FiveDays)}>5 D</Button>
            <Button active={activeOptions === DateRangeOptions.OneMonth} onClick={() => onButtonClick(DateRangeOptions.OneMonth)}>1 M</Button>
            <Button active={activeOptions === DateRangeOptions.SixMonths} onClick={() => onButtonClick(DateRangeOptions.SixMonths)}>6 M</Button>
            <Button active={activeOptions === DateRangeOptions.YearToDate} onClick={() => onButtonClick(DateRangeOptions.YearToDate)}>YTD</Button>
            <Button active={activeOptions === DateRangeOptions.OneYear} onClick={() => onButtonClick(DateRangeOptions.OneYear)}>1 Y</Button>
            <Button active={activeOptions === DateRangeOptions.FiveYears} onClick={() => onButtonClick(DateRangeOptions.FiveYears)}>5 Y</Button>
            <Button active={activeOptions === DateRangeOptions.Max} onClick={() => onButtonClick(DateRangeOptions.Max)} >Max</Button>
        </ButtonGroup>
    </ButtonToolbar>
  )
};

export default DateRangeSelector;

