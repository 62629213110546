import { useRebalanceQuery } from "../../../hooks/useRebalances";
import { useStrategyQuery } from "../../../hooks/useStrategies";
import RebalanceTable from "./RebalanceTable";
import { ReportTable } from "./ReportTable";

const ReportOverview = ({portfolioId}: {
    portfolioId: string;
}) => {
    const portfolioQuery = useStrategyQuery(portfolioId);
    const query = useRebalanceQuery(portfolioQuery.data?.PK, portfolioQuery.data?.["latest-report-id"]);

    if (query.data && Array.isArray(query.data)) {
        return <ReportTable portfolioId={portfolioId} orders={query.data} />
    }

    if (query.data && !Array.isArray(query.data)) {
        return <RebalanceTable portfolioId={portfolioId} orders={query.data.orders} />
    }

    if (query.error) {
        return (
            <div>
                Error fetching rebalance!
            </div>
        );
    }

    return (
        <div>ReportTable</div>
    )
};

export default ReportOverview;
